<template>
  <div>
    <v-text-field type="text" autocomplete="username" hidden></v-text-field>
    <v-text-field
      outlined
      dense
      @input="
        checkPassword(),
          checkPasswordsMatch(),
          $emit('update:password', password)
      "
      v-model="password"
      type="password"
      label="New password"
      autocomplete="new-password"
      :rules="passwordRules"
    >
    </v-text-field>
    <v-text-field
      outlined
      dense
      v-model="repeatPassword"
      @input="
        checkPassword(),
          checkPasswordsMatch(),
          $emit('update:confirmPassword', repeatPassword)
      "
      type="password"
      label="Confirm password"
      class="mb-4"
      :rules="confirmPasswordRules"
    >
    </v-text-field>
    <div class="d-flex justify-center align-center pb-3">
      <span>Password strength:</span>
      <Password
        v-model="password"
        :strength-meter-only="true"
        class="w100 d-flex"
      />
    </div>

    <ul class="text-muted">
      <li :class="{ isValid: containsEightCharacters }">8 Characters</li>
      <li :class="{ isValid: containsNumber }">Contains number</li>
      <li :class="{ isValid: containsLowercase }">Contains lowercase</li>
      <li :class="{ isValid: containsUppercase }">Contains uppercase</li>
      <li :class="{ isValid: containsSpecialCharacter }">
        Contains special character
      </li>
      <li :class="{ isValid: doPasswordsMatch }">Passwords match</li>
    </ul>
  </div>
</template>

<script>
import Password from "vue-password-strength-meter";
export default {
  name: "PasswordValidation",
  components: { Password },
  data() {
    return {
      password: null,
      containsEightCharacters: false,
      containsNumber: false,
      containsLowercase: false,
      containsUppercase: false,
      containsSpecialCharacter: false,
      isPasswordValid: false,
      doPasswordsMatch: false,
      repeatPassword: "",
      valid: false,
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => /\d/.test(v) || "Password must be valid",
        (v) => /[a-z]/.test(v) || "Password must be valid",
        (v) => /[A-Z]/.test(v) || "Password must be valid",
        (v) =>
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(v) ||
          "Password must be valid",
      ],
      confirmPasswordRules: [
        (v) => !!v || "Password is required",
        (v) => /\d/.test(v) || "Password must be valid",
        (v) => /[a-z]/.test(v) || "Password must be valid",
        (v) => /[A-Z]/.test(v) || "Password must be valid",
        (v) =>
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(v) ||
          "Password must be valid",
        (v) => v === this.password || "Passwords must match",
      ],
    };
  },
  props: {
    border: {
      type: Boolean,
      required: false,
      default: true,
    },
    username: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {},
  methods: {
    checkPassword() {
      this.containsEightCharacters = this.password.length >= 8;
      this.containsNumber = /\d/.test(this.password);
      this.containsLowercase = /[a-z]/.test(this.password);
      this.containsUppercase = /[A-Z]/.test(this.password);
      this.containsSpecialCharacter =
        // eslint-disable-next-line no-useless-escape
        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(this.password);

      this.isPasswordValid =
        this.containsEightCharacters &&
        this.containsSpecialCharacter &&
        this.containsLowercase &&
        this.containsUppercase &&
        this.containsNumber;

      if (this.isPasswordValid) {
        this.$emit("update:isPasswordValid", true);
        this.$emit("is-password-valid", true);
      }
    },
    checkPasswordsMatch() {
      const match = Boolean(
        this.password && this.password === this.repeatPassword,
      );

      this.$emit("update:doPasswordsMatch", match);
      this.$emit("do-passwords-match", match);
      this.doPasswordsMatch = match;
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

li {
  margin-bottom: 8px;
  position: relative;
}

li:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.isValid {
  color: rgba(46, 184, 92, 0.8);
}

.isValid:before {
  width: 100%;
}

.checkmark_container {
  border-radius: 50%;
  position: absolute;
  top: -15px;
  right: -15px;
  background: #2ecc71;
  width: 50px;
  height: 50px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s ease;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.checked {
  animation: draw 0.5s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>

<style lang="scss">
.Password {
  margin: 0 !important;
  padding-left: 10px;
}

.Password__strength-meter {
  margin: 0 !important;
  width: 400px;
}
.form-control.is-invalid,
.form-control.is-valid {
  background-position: right calc(1em + 0.1875rem) center !important;
}
</style>
