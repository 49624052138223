<template>
  <SignComponent
    title="Reset your password"
    subTitle="Please enter your email and a new password to log in"
  >
    <div class="d-flex flex-column align-center card-body">
      <v-form v-model="valid" @submit.prevent="handleSubmit" class="w100">
        <v-text-field
          outlined
          dense
          label="Email"
          placeholder="example@gmail.com"
          type="username"
          autocomplete="on"
          v-model="confirmForm.email"
          :rules="emailRules"
        />
        <PasswordValidation
          :password.sync="confirmForm.password"
          :confirmPassword.sync="confirmForm.confirmPassword"
          :username="confirmForm.email"
          @is-password-valid="updateIsPasswordValid"
          @do-passwords-match="updateDoPasswordsMatch"
        />
        <div class="mt-8 d-flex flex-column align-center">
          <v-btn
            color="green"
            class="px-4 mb-4 white--text"
            type="submit"
            :disabled="!valid || loading"
            :loading="loading"
          >
            Confirm and login
          </v-btn>
        </div>
      </v-form>
      <v-alert text class="mt-3" type="error" v-if="!confirmForm.code"
        >Confirmation code is missing or expired.</v-alert
      >
    </div>
    <div class="d-flex justify-center" v-if="isChina">
      {{ icpNotice }}
    </div>
  </SignComponent>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SignComponent from "@/components/SignComponent";
import PasswordValidation from "@/components/PasswordValidation";
import { isChina, icpNotice } from "@/helpers/variables";

export default {
  name: "ResetPassword",
  components: {
    SignComponent,
    PasswordValidation,
  },
  data() {
    return {
      confirmForm: {
        email: null,
        code: null,
        password: null,
        confirmPassword: null,
      },
      valid: false,
      isPasswordValid: false,
      doPasswordsMatch: false,
      submitted: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      isChina,
      icpNotice,
    };
  },
  beforeMount() {
    const code = this.$route.query.code;

    if (code) {
      this.confirmForm.code = code;
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.user.loading.login,
    }),
    isFormValid() {
      return (
        this.valid &&
        this.confirmForm.code !== null &&
        this.isPasswordValid &&
        this.doPasswordsMatch
      );
    },
  },
  methods: {
    updateIsPasswordValid(value) {
      this.isPasswordValid = value;
    },
    updateDoPasswordsMatch(value) {
      this.doPasswordsMatch = value;
    },
    ...mapActions({
      login: "user/login",
      confirmPasswordCode: "user/confirmPasswordCode",
    }),
    async handleSubmit() {
      await this.confirmPasswordCode({
        email: this.confirmForm.email,
        password: this.confirmForm.password,
        code: this.confirmForm.code,
      });

      const loginForm = {
        email: this.confirmForm.email,
        password: this.confirmForm.password,
      };

      const loginRes = await this.login(loginForm);

      this.submitted = true;

      if (loginRes) {
        await this.$router.push({ path: "/" }).catch((err) => {
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location",
            )
          ) {
            console.error(err);
          }
        });
      }
    },
  },
};
</script>
